import "./App.scss";
import './Css/style.css';
import './Css/responsive.css';
import "./scripts";
import { HashRouter, BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import Cookies from "universal-cookie";
import appConstants from "./config/AppConstants";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";


const cookie = new Cookies();

const Layout = React.lazy(() => import("./components/Layout/CommonLayout"));
const DefaultLayout = React.lazy(() =>
  import("./components/Layout/AdminLayout")
);
const StudentLayout = React.lazy(() =>
  import("./components/Layout/StudentLayout")
);
const Login = React.lazy(() => import("./components/Common/Login"));
const LoginAdmin = React.lazy(() => import("./components/Common/LoginAdmin"));
const Signup = React.lazy(() => import("./components/Common/Signup"));
const VerifyEmail = React.lazy(() => import("./components/Common/VerifyEmail"));
// const ForgotPassword = React.lazy(() => import("./components/Common/ForgotPassword"));
const ForgotPassword = React.lazy(() =>
  import("./components/Common/newForgotPassword")
);
// const ResetPassword = React.lazy(() => import("./components/Common/ResetPassword"));
const ResetPassword = React.lazy(() =>
  import("./components/Common/newResetPassword")
);
const studentPaymentSuccess = React.lazy(() =>
  import("./components/Common/studentPaymentSuccess")
);
const studentPaymentFailure = React.lazy(() =>
  import("./components/Common/studentPaymentFailure")
);
const NewLogin = React.lazy(() => import("./components/Common/newLogin"));
const SuperAdminLogin = React.lazy(() => import("./components/Common/superAdminLogin"));
const NewSignUp = React.lazy(() => import("./components/Common/newSignUp"));
const Oauth2Login = React.lazy(() => import("./components/Common/Oauth2Login"));



const loading = () =>
  // <div className="custm-loader"><Loader /></div>
  null;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      token: "",
      loginType: "",
    };
  }

  componentWillMount() {
    const sessionUser = cookie.get("user", { path: "/" }),
      jwtSession = cookie.get("token", { path: "/" }),
      loginType = parseFloat(cookie.get("loginType", { path: "/" }));

    this.setState({
      user: sessionUser || null,
      token: jwtSession || null,
      loginType: loginType || null,
    });
  }
  render() {
    let { user, loginType } = this.state;
    let role = (user && user.role && user.role.name) || "";
    return (
      <>
        <ToastContainer />
        <div id="app-main-loader">
          <div className="loader-wrapper">
            <img src="img/main-loader.gif" />
          </div>
        </div>
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" component={NewLogin} />
              <Route exact path="/super-admin/login" component={SuperAdminLogin} />


              <Route exact path="/admin/login" >
                <Redirect to={"/login"} />
              </Route>

              {/* <Route exact path="/signup" component={NewSignUp} /> */}
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route
                exact
                path="/verify-email/:type/:userId/:token"
                component={VerifyEmail}
              />
              <Route
                exact
                path="/forgotPassword/:type/:userId/:token"
                component={ResetPassword}
              />
              {/* <Route
                exact
                path="/student/payment-success/"
                component={studentPaymentSuccess}
              />
              <Route
                exact
                path="/student/payment-failure/"
                component={studentPaymentFailure}
              />
              <Route exact path="/oauth2/auth" component={Oauth2Login} /> */}
              <Route
                path="/"
                // exact={true}
                render={(props) =>
                  this.state.token != null ? (
                    <>
                      {
                        loginType == appConstants.loginType.STUDENT ||
                          loginType == appConstants.loginType.FACULTY ||
                          role === appConstants.ROLES.trainer.name ||
                          role === appConstants.ROLES.evaluator.name ||
                          role === appConstants.ROLES.counsellor.name ? (
                          <StudentLayout {...props}></StudentLayout>
                        ) : null
                        // :  loginType == appConstants.loginType.FACULTY ?  <DefaultLayout {...props}></DefaultLayout> : null
                      }
                      {/* {
                      this.state.loginType === 1 ?
                        <StudentLayout {...props}></StudentLayout>
                        : null
                    }
                    {this.state.loginType === 2 ?
                      <DefaultLayout {...props}></DefaultLayout> : ''
                    } */}
                    </>
                  ) : (
                    // <Layout {...props}></Layout>
                    <Redirect to={"/admin/login"} />
                  )
                }
              />
            </Switch>
          </React.Suspense>
        </Router>
      </>
    );
  }
}

export default App;
